<template>
  <div>
    <div class="page-header">
      <img class="bg" src="@/assets/page-header-bg.png" alt="" />
      <span class="text">月增资产总价值统计</span>
    </div>

    <div>
      <div class="charts">
        <div>
          <Chart :list="list" />
        </div>
        <div>
          <Chart2 :list="list" />
        </div>
      </div>

      <a-select :allowClear="false" v-model="activeIndex">
        <a-select-option v-for="(item, index) in list" :key="index">{{
          item.month
        }}</a-select-option>
      </a-select>

      <div style="padding-top: 2vh"></div>

      <a-table
        bordered
        :data-source="filteredList"
        :loading="loading"
        rowKey="id"
      >
        <a-table-column title="批次编号" data-index="code" align="center" />
        <a-table-column title="资产名称" data-index="name" />
        <a-table-column title="品牌" data-index="brand" />
        <a-table-column title="数量" data-index="number" />

        <a-table-column title="规格型号" data-index="specification" />

        <a-table-column title="单位" data-index="unit" align="center" />

        <a-table-column title="单价(元)" align="right">
          <template slot-scope="text">
            <Money :money="text.price" />
          </template>
        </a-table-column>
        <a-table-column title="总价(元)" align="right">
          <template slot-scope="text">
            <Money :money="text.priceTotal" />
          </template>
        </a-table-column>

        <a-table-column
          title="批次采购申请时间"
          data-index="createAt"
          align="center"
        >
        </a-table-column>
      </a-table>
    </div>
  </div>
</template>
<script>
import { fetchData } from "../api/analysis";

import Chart from "./chart.vue";
import Chart2 from "./chart2.vue";
export default {
  components: {
    Chart,
    Chart2,
  },
  data() {
    return {
      list: [],
      loading: false,
      activeIndex: 0,
    };
  },

  computed: {
    filteredList() {
      const item = this.list[this.activeIndex];
      return item && Array.isArray(item.list) ? item.list : [];
    },
  },

  mounted() {
    this.loading = true;
    fetchData()
      .then((res) => {
        console.log("res", res);
        this.list = Array.isArray(res) ? Object.freeze(res) : [];
      })
      .finally(() => {
        this.loading = false;
      });
  },
};
</script>

<style lang="less" scoped>
.charts {
  margin-bottom: 1vh;
  display: flex;

  & > div {
    flex: 1;
  }
}
</style>



<style lang="less" scoped>
.page-header {
  position: relative;
  margin-bottom: 1vh;
  .bg {
    width: 240px;
    display: block;
  }

  .text {
    position: absolute;
    top: 10%;
    left: 0;
    bottom: 0;
  }
}
</style>